import React from 'react'


import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import SelectAccount from './SelectAccount'
import Profile from './Profile'
import GeneralService from './GeneralService'


require('es6-promise').polyfill();
require('isomorphic-fetch');

export default class Main extends React.Component {
    render(){
        return(
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/select-account' component={SelectAccount}/>
                <Route exact path='/profile' component={Profile}/>
                <Route exact path='/general-service' component={GeneralService}/>
            </Switch>
        )
    }
}
